import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import Seo from '../../components/seo/Seo';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

import learn from '../../images/learn.png';
import verify from '../../images/verify.png';
import fail from '../../images/unauthorized.png';

import {
  DEMO_LINK,
  USER_GUIDE_LINK,
  MAIL_TO_EMAIL
} from '../../constants';

import Section from '../../components/Section';
import styles from './DemoPage.module.scss';
import './Slider.scss';

import exclamation from '../../images/exclamation.svg';

const SETTINGS = {
  dots: true,
  speed: 500,
  arrows: true,
  infinite: false,
  centerMode: true,
  centerPadding: '150px',
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '100px',
        arrows: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '50px',
        arrows: true,
      }
    }
  ]
};

const mailToLink = `mailto:${MAIL_TO_EMAIL}`;

const linkStyle = [
  'col-12 col-md-8 mx-auto',
  styles.Link,
].join(' ');

const DemoPage = () => (
  <>
    <Seo />
    <Header />
    <main>
      <Section
        title="Graboxy Sentinel Demo"
        className="container"
        gradient
        uppercase
      >
        <div className="row text-center">
          <div className={linkStyle} style={{color: '#ffffff'}}>
            Download the
            {' '}
            <a href={DEMO_LINK} download>Graboxy Sentinel Demo</a>
            {' '}
            application to your Windows PC to try our continuous authentication
            tool.
            <br/>
            <br/>
            Once installed, the application creates your unique biometric
            cursor movement profile and pops up a notification when our AI
            thinks that another person is using your computer. Read the
            {' '}
            <a href={USER_GUIDE_LINK} download>User Guide</a>
            {' '}
            for more or
            {' '}
            <a href={mailToLink}>get in touch</a>
            {' '}
            and we’ll be happy to answer your questions.
          </div>

          <div className="col-12 text-center mt-4 mb-5">
            <a href={DEMO_LINK} className="btn mx-2 my-2" style={{ width: 220 }}>
              Download Demo
            </a>

            <a
              href={USER_GUIDE_LINK}
              className="btn mx-2 my-2"
              style={{ width: 220 }}
              download
            >
              Download User Guide
            </a>
          </div>
          <div
            className="col-8 offset-2 text-center
            pb-4 px-3 pt-4 border border-danger"
          >
            <img
              src={exclamation}
              alt="alert"
              style={{ "maxWidth": "3rem" }}
              className="mb-3"
            />
            <h4>
              <strong>IMPORTANT:</strong> Only one person and one mouse must be
              involved during the Training Phase. Changing users, using touchpads
              or other pointing devices during the Training Phase will stop the
              Demo from working properly.
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className={styles.Title}>Get going in less than a minute</h2>
            <Slider {...SETTINGS} className="demoSlider">
              <div>
                <img src={learn} alt="learn" />
                <p>
                  The training phase begins automatically after installing the
                  Demo and lasts a few days depending on your mouse usage.
                </p>
              </div>
              <div>
                <img src={verify} alt="verify" />
                <p>
                  Once training is complete, the authentication score gets
                  continuously updated based on your activity of the last few
                  minutes.
                </p>
              </div>
              <div>
                <img src={fail} alt="fail" />
                <p>
                  Low authentication score triggers an alert notification. You
                  can switch cursor movement monitoring on and off at any time.
                </p>
              </div>
            </Slider>
          </div>
          <div className="col-12 text-center pt-4">
            <a href={mailToLink} className="btn mx-2 my-2" style={{ width: 220 }}>
              Get in Touch
            </a>
          </div>
        </div>
      </Section>
    </main>
    <Footer>
      <Link to="/demo-privacy" className="mx-5" target="_blank">
        Demo Privacy Policy
      </Link>
    </Footer>
  </>
);

export default DemoPage;
