import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';

const ContactButton = () => {
  const { pathname } = useLocation();

  if (!pathname || pathname === '/') {
    return (
      <ScrollLink
        to="Contact"
        spy={true}
        smooth={true}
        className="btn"
      >
        Contact
      </ScrollLink>
    );
  }

  return (
    <HashLink
      to='/#Contact'
      className="btn"
      target="_blank"
      rel="noopener noreferrer"
    >
      Contact
    </HashLink>
  );
};

export default ContactButton;
