import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Footer = ({ children }) => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 footer__left">
          <Link to="/privacy" target="_blank" className="mr-4">
            Privacy Policy
          </Link>
          {children}
        </div>
        <div className="col-lg-6 footer__right">
          <p>
            © 2023,
            <a>
              Patient-Record Ltd.
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = {
  children: undefined,
};

export default Footer;
