import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import PostCardImg from './PostCardImg';
import Badges from './Badges';
import styles from './PostCard.module.scss';

const PostCard = ({
  post: {
    featuredImage,
    title,
    date,
    author,
    excerpt,
    link,
    tags,
  }
}) => {
  const onClick = useCallback(() => {
    window.location.href = link;
  }, [link]);

  return (
    <div className="my-3 col-12 px-3">
      <div className={styles.PostCard} onClick={onClick} role="link">
        <PostCardImg post={{ featuredImage, link }} />

        <div className="card-body">
          <div className="mb-2" style={{ fontSize: 14, color: '#f8e3ff' }}>
            <strong>{author.name}</strong>
            <span className="ms-2">{date}</span>
          </div>

          <h3 className={styles.CardTitle}>
            <a
              href={link}
              dangerouslySetInnerHTML={{ __html: title.rendered }}
            />
          </h3>

          <div
            className={styles.CardText}
            dangerouslySetInnerHTML={{ __html: excerpt.rendered }}
          />
        </div>

        <div className="px-3">
          <a href={link} className={styles.ReadMore}>Read more</a>
        </div>

        <div className={styles.CardFooter}>
          <Badges badges={tags} limit={2} />
        </div>
      </div>
    </div>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({}),
};

PostCard.defaultProps = {
  post: {
    link: '#',
    title: { rendered: '' },
    date: '',
    author: { name: 'Admin' },
    excerpt: { rendered: '' },
    featuredImage: undefined,
    tags: [],
  },
};

export default PostCard;
