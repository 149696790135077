import React from 'react';
import PropTypes from 'prop-types';

import './PostCardImg.scss';

const PostCardImg = ({
  post: {
    featuredImage,
    link,
  }
}) => (
  <a className="card-img" href={link}>
    {featuredImage && (
      <img
        src={featuredImage}
        className="card-img-top img-fluid"
        alt="Featured"
      />
    )}
  </a>
);

PostCardImg.propTypes = {
  post: PropTypes.shape({}).isRequired,
};

PostCardImg.defaultProps = {
  post: {
    link: '#',
    featuredImage: undefined,
  },
};

export default PostCardImg;
