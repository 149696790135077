import { useEffect } from 'react';

import DocumentLayout from '../layouts/DocumentLayout';
import Seo from '../components/seo/Seo';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

const DemoPrivacyPolicyPage = () => (
  <DocumentLayout>
    <section className="privacy">
      <Seo
        title="Graboxy Sentinel Privacy"
        description="Graboxy Sentinel Privacy"
      />

      <div className="container">
        <ScrollToTopOnMount />
        <h2>
          PRIVACY POLICY
        </h2>
        <span>
          Effective date: 2022-05-13
        </span>
        <h3>Introduction</h3>
        <p>
          Welcome to the
          {' '}
          <a href="https://sentinel.graboxy.com">Graboxy Sentinel Demo</a>
          .
        </p>
        <p>
          Cursor Insight Ltd., registered at 20-22 Wenlock Road, London, N1 7GU,
          United Kingdom (“We”, “Us”, “Our”). appreciate the interest you
          (“
          <em>you</em>
          ” or “
          <em>your</em>
          ”) have shown in Our software
          {' '}
          - Graboxy Sentinel Demo -
          {' '}
          by visiting Our Graboxy Sentinel Demo website:
          {' '}
          <a href="https://demo.graboxy.com/">https://demo.graboxy.com/</a>
          {' '}
          (“Website”).
        </p>
        <p>
          This Privacy Policy outlines Our guidelines for the information the
          Graboxy Sentinel Demo software (“Software”) collects when You install
          or use Our Software.
        </p>
        <p>
          Topics:
        </p>
        <ul>
          <li>Data collected by the Software.</li>
          <li>How do We store Your data?</li>
          <li>Retention of data.</li>
          <li>What are Your data protection rights?</li>
          <li>Changes to Our Softwares privacy policy.</li>
          <li>How to contact Us.</li>
        </ul>
        <h3>Data collected by the Software</h3>
        <p>
          The Software collects the following data when the “Data collection
          enabled” setting is active:
        </p>
        <ul>
          <li>
            <strong>Mouse data:</strong>
            <ul>
              <li>Position of mouse pointer (x and y coordinates);</li>
              <li>
                Mouse events (pressing and releasing buttons, click, double
                click);
              </li>
              <li>Mouse scroll.</li>
            </ul>
          </li>
          <li>
            <strong>Metadata:</strong>
            <ul>
              <li>Name and parameters of CPU(s);</li>
              <li>Type and resolution of display(s).</li>
            </ul>
          </li>
          <li>
            <strong>Metadata (once per client launch):</strong>
            <ul>
              <li>Email address provided during installing the Software;</li>
              <li>Unique anonymous user identifier;</li>
              <li>Unique anonymous device identifier;</li>
              <li>Unique anonymous user session identifier;</li>
              <li>System time;</li>
              <li>Operating system version;</li>
              <li>Type of pointing device;</li>
              <li>External IP address of the computer.</li>
            </ul>
          </li>
          <li>
            Name and window position of the active foreground application
            (at every change of the active foreground application and when
              resizing the foreground window).
          </li>
        </ul>
        <p>
          The collected data is sent to Our data server via HTTP protocol in
          text format; up to once per minute, but only if data has been
          generated since the last transmission. The collected <i>data is not
          stored</i> by the client application.
        </p>
        <p>
          In particular, <i>We do not collect data</i> from which any sensitive
          information can be reconstructed, so <i>We do not collect</i>
          keystrokes, the addresses of websites You visit, the names or
          contents of documents You open, passwords, IDs, or any similarly
          sensitive personal information.
        </p>
        <h3>How do we store your data?</h3>
        <p>
          Cursor Insight securely stores your data at Contabo Gmbh.,
          Aschauer Str. 32a, 81549 München, Germany.
        </p>
        <h4>Retention of Data</h4>
        <p>
          We will retain Your Personal Data only for as long as is necessary
          for the purposes set in this Privacy Policy. We will retain and use
          Your Personal Data to the extent necessary to comply with Our legal
          obligations (for example, if we are required to retain Your data to
            comply with applicable laws), resolve disputes, and enforce Our
            legal agreements and policies.
        </p>
        <p>
          We will also retain Usage Data for internal analysis purposes.
          Usage Data is generally retained for a shorter period, except when
          this data is used to strengthen the security or to improve the
          functionality of our Service, or We are legally obligated to retain
          this data for longer time periods.
        </p>
        <h4>What are Your data protection rights?</h4>
        <p>
          Cursor Insight would like to make sure You are fully aware of all of
          Your data protection rights. Every user is entitled to the following:
        </p>
        <ul>
          <li>
            <strong>The right to access</strong>
            {' '}
            – You have the right to request Cursor Insight for copies of Your
            personal data. We may charge you a small fee for this service.
          </li>
          <li>
            <strong>The right to rectification</strong>
            {' '}
             – You have the right to request that Cursor Insight correct any
             information You believe is inaccurate. You also have the right to
             request Cursor Insight to complete the information You believe is
             incomplete.
          </li>
          <li>
            <strong>The right to erasure</strong>
            {' '}
             – You have the right to request that Cursor Insight erase Your
             personal data, under certain conditions.
          </li>
          <li>
            <strong>The right to restrict processing</strong>
            {' '}
             – You have the right to request that Cursor Insight restrict the
             processing of Your personal data, under certain conditions.
          </li>
          <li>
            <strong>The right to object to processing</strong>
            {' '}
             – You have the right to object to Cursor Insight’s processing of
             Your personal data, under certain conditions.
          </li>
          <li>
            <strong>The right to data portability</strong>
            {' '}
             – You have the right to request that Cursor Insight transfer the
             data that We have collected to another organization, or directly
             to You, under certain conditions.
          </li>
        </ul>
        <p>
          If You make a request, We have one month to respond to You. If you
          would like to exercise any of these rights, please contact Us at
          Our email:
          {' '}
          <a href="mailto:privacy@cursorinsight.com">
            privacy@cursorinsight.com
          </a>
          {' '}
          .
        </p>
        <h3>Changes to Our privacy policy</h3>
        <p>
          Cursor Insight keeps its privacy policy under regular review and
          places any updates on this web page.
        </p>
        <h3>How to contact us</h3>
        <p>
          If You have any questions about Graboxy Demo’s privacy policy, the
          data we hold on You, or You would like to exercise one of Your data
          protection rights, please do not hesitate to contact us.
        </p>
        <p>
          Email us at:
          {' '}
          <a href="mailto:privacy@cursorinsight.com">
            privacy@cursorinsight.com
          </a>
          {' '}
          .
        </p>
      </div>
    </section>
  </DocumentLayout>
);

export default DemoPrivacyPolicyPage;
