import React from 'react';
import PropTypes from 'prop-types';

import styles from './UseCase.module.scss';

const UseCase = ({ img, title, children }) => (
  <div className="col-12 col-lg-4">
    <div className={styles.UseCase}>
      <div>
        <img src={img} alt={title} />
      </div>

      <h3>{title}</h3>

      <p className="px-0 px-md-1 px-lg-3">
        {children}
      </p>
    </div>
  </div>
);

UseCase.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default UseCase;
