import React from 'react';
import Reveal from 'react-reveal/Reveal';

import PageLayout from '../layouts/PageLayout';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Email from '../components/Email';
import UseCases from '../components/UseCases';
import Steps from '../components/steps/Steps';
import Dashboard from '../components/Dashboard';
import Quotes from '../components/Quotes';
import Awards from '../components/Awards';
import Blog from '../components/Blog';
import Contact from '../components/Contact';

const IndexPage = () => (
  <PageLayout>
    <Reveal effect="fadeInUp">
      <Hero />
    </Reveal>
    <Reveal effect="fadeInUp">
      <Features />
    </Reveal>
    <Reveal effect="fadeInUp">
      <Email />
    </Reveal>
    <Reveal effect="fadeInUp">
      <UseCases />
    </Reveal>
    <Reveal effect="fadeInUp">
      <Steps />
    </Reveal>
    <Reveal effect="fadeInUp">
      <Dashboard />
    </Reveal>
    <Reveal effect="fadeInUp">
      <Quotes />
    </Reveal>
    <Reveal effect="fadeInUp">
      <Awards />
    </Reveal>
    <Reveal effect="fadeInUp">
      <Blog />
    </Reveal>
    <Contact />
  </PageLayout>
);

export default IndexPage;
