import React from 'react';

import Video from '../Video';
import styles from './VideoContainer.module.scss';

const VideoContainer = () => (
  <div className={styles.VideoContainer}>
    <Video
      src="https://player.vimeo.com/video/694347500?h=b41ff280fe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      title="Graboxy Sentine PoC Solitaire"
    />
  </div>
);

export default VideoContainer;
