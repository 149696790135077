import React from 'react';
import Reveal from 'react-reveal/Reveal';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';


import IndexPage from './pages/IndexPage';
import DemoPage from './pages/DemoPage';
import DemoForm from './pages/DemoForm';
import StayCompliant from './pages/StayCompliant';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import DemoPrivacyPolicyPage from './pages/DemoPrivacyPolicyPage';
import PrototypeVideoPage from './pages/PrototypeVideoPage';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/demo" element={<DemoPage />} />
      <Route path="/demoform" element={<DemoForm />} />

      <Route path="privacy" element={
        <Reveal effect="fadeInUp">
          <PrivacyPolicyPage />
        </Reveal>
      } />

      <Route path="demo-privacy" element={
        <Reveal effect="fadeInUp">
          <DemoPrivacyPolicyPage />
        </Reveal>
      } />

      <Route path="prototype-video" element={
        <Reveal effect="fadeInUp">
          <PrototypeVideoPage />
        </Reveal>
      } />

      <Route path="stay-compliant-with-nis2-hipaa-soc2" element={
        <Reveal effect="fadeInUp">
          <StayCompliant />
        </Reveal>
      } />
    </Routes>
  </BrowserRouter>
);

export default App;
