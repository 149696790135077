export const GRABOXY_URL = process.env.REACT_APP_GRABOXY_URL ?? '';

export const WP_API_URL = process.env.REACT_APP_WP_API_URL ?? '';

export const DEMO_LINK = process.env.REACT_APP_DEMO_LINK ?? '';

export const USER_GUIDE_LINK = process.env.REACT_APP_USER_GUIDE_LINK ?? '';

export const MAIL_TO_EMAIL = process.env.REACT_APP_MAIL_TO_EMAIL ?? '';

export const CAPTCHA_URL_BASE = process.env.REACT_APP_CAPTCHA_URL_BASE
  ?? 'https://captcha.graboxy.com/?UNCONFIGURED=1';

export const GRABOXY_API_KEY =
  process.env.REACT_APP_GRABOXY_API_KEY ?? 'UNCONFIGURED';

export const TRAP_SERVER_URL = process.env.REACT_APP_TRAP_SERVER_URL
  ?? 'https://trap.graboxy.com/?UNCONFIGURED=1';
