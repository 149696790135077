import { useNavigate } from 'react-router-dom';
import {
  ReactComponent as BackToHomeIcon,
} from '../../images/back-to-home.svg';

const BackToHome = () => {
  const navigate = useNavigate();
  return (
    <div className="back-to-home">
      <button
        type="button"
        onClick={() => { navigate('/'); }}
      >
        <BackToHomeIcon />
        Back to home
      </button>
    </div>
  );
};

export default BackToHome;
