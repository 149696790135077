import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Slider from 'react-slick';

import Seo from '../../components/seo/Seo';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Section from '../../components/Section';
import Contact from '../../components/Contact';
import Video from '../../components/Video';
import Awards from '../../components/Awards';

import './StayCompliant.scss';
import styles from './StayCompliant.module.scss';

import partner1 from '../../images/partner-otp.png';
import partner2 from '../../images/partner-konica.png';
import partner3 from '../../images/partner-decathlon.png';
import partner4 from '../../images/partner-dsk.png';
import partner5 from '../../images/partner-cardiff.png';
import partner6 from '../../images/partner-nbsz.png';
import partner7 from '../../images/partner-mokk.png';
import partner8 from '../../images/partner-prezi.png';
import partner9 from '../../images/partner-sanofi.png';
import partner10 from '../../images/partner-hungaro.png';
import partner11 from '../../images/partner-natwest.png';
import partner12 from '../../images/partner-catapult.png';
import partner13 from '../../images/partner-wiseguys.png';
import partner14 from '../../images/partner-erlang.png';
import partner15 from '../../images/partner-neurology.png';
import partner16 from '../../images/partner-microsoft.png';
import cloudservice from '../../images/cloudservice.svg';
import datacenters from '../../images/datacenters.svg';
import softwareproviders from '../../images/softwareproviders.svg';
import managedservice from '../../images/managedservice.svg';
import providers from '../../images/providers.svg';
import digital from '../../images/digital.svg';
import handling from '../../images/handling.svg';
import list from '../../images/list.svg';
import soc2 from '../../images/soc2.png';
import nis2 from '../../images/nis2.png';
import hipaa from '../../images/hipaa.png';

const SETTINGS = {
  speed: 6000,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false,
};

const PreventDeviceSharing = () => (
  <>
    <Seo />
    <Header />
    <main className="staycompliant">
      <Section
        className={styles.StayCompliant}
        id="staycompliant"
        gradient
        uppercase
      >
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h1>
                Stay Compliant with
                <br />
                NIS 2 / HIPAA / SOC 2
              </h1>
              <p className="intro">
                Comply with HIPAA, SOC 2, NIS 2 by preventing unauthorized
                <br />
                access to sensitive client data.
              </p>
              <ScrollLink
                to="Contact"
                spy={true}
                smooth={true}
                className="btn"
              >
                Contact Us
              </ScrollLink>
            </div>
          </div>
        </div>
      </Section>
      <Section
        className={`dark mt-0 ${styles.Partners}`}
        title="Our Partners"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Slider {...SETTINGS} className="PartnerSlider">
                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner1} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner4} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner3} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner2} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <p>
                      <img src={partner7} alt="Partner" />
                      {' '}
                      <span>
                        Association of Notaries
                      </span>
                    </p>
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <div className={styles.SliderItem}>
                      <img src={partner9} alt="Partner" />
                    </div>
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner5} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner8} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner10} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div className={styles.SliderItemFlex}>
                    <img src={partner6} alt="Partner" />
                    {' '}
                    <p className="d-none d-md-block">
                      Special Service for
                      <br />
                      National Security
                    </p>
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div className="p-0">
                    <p className="my-0">
                      <img
                        src={partner15}
                        alt="Partner"
                        className="mx-auto mb-2"
                      />
                      {' '}
                      <p className="my-0 d-none d-md-block">
                        National Institue of Mental Health,
                        <br />
                        Neurology and Neurosurgery
                      </p>
                    </p>
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner16} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <p className="my-0">
                      <img src={partner11} alt="Partner" />
                      {' '}
                      <p className="mt-3 mb-0">
                        Fintech Accelerator
                      </p>
                    </p>
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner13} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner12} alt="Partner" />
                  </div>
                </div>

                <div className={styles.SliderItem}>
                  <div>
                    <img src={partner14} alt="Partner" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Section>
      <Section
        title="How does Graboxy Sentinel Help you to stay
              NIS 2 / HIPAA / SOC 2 Compliant?"
      >
        <div className="container">
          <div className="row">
            <div className="text-left col-md-6">
              <p>
                Graboxy Sentinel is an extra security layer to prevent account
                takeover attacks and the resulting data breaches.
              </p>
              <p>
                Graboxy Sentinel analyzes a user’s cursor movements to create
                identity profiles. If the real-time cursor movement analysis
                shows a divergence from the user’s biometric profile,
                Graboxy Sentinel flags the fraudulent user accessing the
                account.
              </p>
              <p>
                Flagged users can be locked out or re-verified using
                traditional multi-factor authentication methods.
              </p>
              <div className="text-center mt-md-4">
                <a
                  className="btn mt-md-4 mb-0" href="https://sentinel.graboxy.com/demo"
                  target="_blank"
                >
                  Download Graboxy Sentinel Demo for Free
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <Video
                src="https://player.vimeo.com/video/694347500?h=b41ff280fe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                title="Graboxy Sentine PoC Solitaire"
              />
            </div>
          </div>
        </div>
      </Section>
      <Awards />
      <Section
        title="How Graboxy Sentinel can help you to  Stay NIS 2 compliant"
        className="dark"
      >
        <div className="container">
          <div className="row my-3">
            <div
              className="col-md-6 text-center d-flex justify-content-center
              align-items-center mb-5 mb-md-0"
            >
              <img src={nis2} width={400} />
            </div>
            <div className="col-md-6 d-flex justify-content-center flex-column">
              <p className="text-right">
                Graboxy Sentinel offers an additional layer of security for
                organizations and can help meet several NIS2 requirements,
                including:
              </p>
              <ul>
                <li>access controls,</li>
                <li>data protection,</li>
                <li>and incident response planning.</li>
              </ul>
              <p>
                Prevent potential financial losses, reputational damage, and
                legal consequences that can result from cyber attacks.
              </p>
            </div>
            <div className="text-center">
              <a
                className="btn mt-md-4 mb-0" href="https://sentinel.graboxy.com/demo"
                target="_blank"
              >
                Download Graboxy Sentinel Demo
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <div className="col-12 mt-5">
              <h2>What companies have to be NIS 2 compliant?</h2>
            </div>
            <div className="col-12">
              <p>
                NIS 2 (Network and Information Systems) is a European Union
                Directive.
              </p>
            </div>
            <div className="col-md-4 mt-5">
              <img src={digital} width={100} height={150} />
              <p className="mt-3">Digital service providers</p>
            </div>
            <div className="col-md-4 mt-5">
              <img src={providers} width={100} height={150} />
              <p className="mt-3">Providers of essential services</p>
            </div>
            <div className="col-md-4 mt-5">
              <img src={list} width={80} height={150} />
              <p className="mt-3">
                Check the full list
                {' '}
                <a
                  href="https://graboxy.com/post/1131/everything-you-should-know-about-nis-2"
                  target="_blank"
                >
                  here
                </a>
              </p>
            </div>
          </div>
          <div className="text-center">
            <a
              className="btn mt-3 mb-0"
              target="_blank"
              href="https://graboxy.com/post/1131/everything-you-should-know-about-nis-2"
            >
              Learn more about SOC 2 compliance
            </a>
          </div>
        </div>
      </Section>
      <Section
        title="How Graboxy Sentinel can help you to  Stay HIPAA compliant"
      >
        <div className="container">
          <div className="row my-3">
            <div
              className="col-md-6 d-flex flex-column order-2 order-md-1
              justify-content-center"
            >
              <p>
                A continuous authentication tool is key for HIPAA compliance
                and to safeguard electronic protected health information (ePHI).
              </p>
              <p>
                Graboxy Sentinel can help you reach the requirements set by
                HIPAA’s Security Rule which requires covered entities and
                business associates to ensure the following:
              </p>
              <ul>
                <li>confidentiality,</li>
                <li>integrity,</li>
                <li>and availability of ePHI.</li>
              </ul>
            </div>
            <div
              className="col-md-6 text-center d-flex justify-content-center
              align-items-center order-md-2 mb-3 mb-md-0"
            >
              <img src={hipaa} width={400} />
            </div>
          </div>
          <div className="text-center">
            <a
              className="btn mb-0" href="https://sentinel.graboxy.com/demo"
              target="_blank"
            >
              Download Graboxy Sentinel Demo
            </a>
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <div className="col-12 mt-5">
              <h2>What companies have to be HIPAA compliant?</h2>
            </div>
            <div className="col-12">
              <p>
                Health Insurance Portability and Accountability Act is a US
                federal law.
              </p>
            </div>
            <div className="col-md-4 offset-md-4 col-12 mt-3">
              <img src={handling} width={100} />
              <p className="mt-3">
                Companies handling protected health information (PHI)
              </p>
            </div>
          </div>
          <div className="text-center">
            <a
              className="btn mt-3 mb-0"
              target="_blank"
              href="https://graboxy.com/post/1141/everything-you-should-know-about-hipaa"
            >
              Learn more about HIPAA compliance
            </a>
          </div>
        </div>
      </Section>
      <Section
        className="dark"
        title="How Graboxy Sentinel can help you to  Stay SOC 2 compliant"
      >
        <div className="container">
          <div className="row mb-5">
            <div
              className="col-md-6 text-center d-flex justify-content-center
              align-items-center mb-5 mb-md-0"
            >
              <img src={soc2} width={400} />
            </div>
            <div className="col-md-6 d-flex justify-content-center flex-column">
              <p>
                With Graboxy Sentinel, you can ensure that your organization
                meets the SOC 2 requirements for:
              </p>
              <ul>
                <li>security,</li>
                <li>availability,</li>
                <li>processing integrity,</li>
                <li>confidentiality,</li>
                <li>and privacy.</li>
              </ul>
              <p>
                Our tool provides continuous monitoring and real-time alerts
                to help you stay ahead of potential threats.
              </p>
            </div>
            <div className="text-center">
              <a
                className="btn mb-0" href="https://sentinel.graboxy.com/demo"
                target="_blank"
              >
                Download Graboxy Sentinel Demo
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <div className="col-12 mt-5">
              <h2>What companies have to be SOC 2 compliant?</h2>
            </div>
            <div className="col-12">
              <p>
                Service Organization Control 2 compliance is not mandatory by
                law, but can provide a competitive advantage for:
              </p>
            </div>
            <div className="col-md-4 offset-md-2 mt-5">
              <img src={cloudservice} width={100} height={110} />
              <p className="mt-3">Cloud service providers</p>
            </div>
            <div className="col-md-4 mt-5">
              <img src={datacenters} width={100} height={110} />
              <p className="mt-3">Data centers</p>
            </div>
            <div className="col-md-4 offset-md-2 mt-5">
              <img src={softwareproviders} width={100} height={110} />
              <p className="mt-3">Software as a service providers</p>
            </div>
            <div className="col-md-4 mt-5">
              <img src={managedservice} width={100} height={110} />
              <p className="mt-3">Managed service providers</p>
            </div>
          </div>
          <div className="text-center">
            <a
              className="btn mt-3 mb-0"
              target="_blank"
              href="https://graboxy.com/post/1156/what-is-soc-2-principles-types-requirements"
            >
              Learn more about SOC 2 compliance
            </a>
          </div>
        </div>
      </Section>
      <Contact />
    </main>
    <Footer>
      <Link to="/demo-privacy" className="mx-5" target="_blank">
        Demo Privacy Policy
      </Link>
    </Footer>
  </>
);

export default PreventDeviceSharing;
