import React from 'react';
import PropTypes from 'prop-types';

import styles from './Video.module.scss';

const Video = ({ src, title }) => {
  return (
    <iframe
      src={src}
      title={title}
      className={styles.Video}
      width="540"
      height="315"
      frameBorder="0"
      allowFullScreen
    />
  );
};

Video.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
};

Video.defaultProps = {
  src: 'https://player.vimeo.com/video/694347500?h=b41ff280fe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  title: 'Graboxy Sentine PoC Solitaire',
};

export default Video;
