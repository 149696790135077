import React from 'react';
import PropTypes from 'prop-types';

import styles from './Title.module.scss';

const Title = ({ id, children, gradient, uppercase }) => (
  <div className="container">
    <div className="row text-center">
      <div className={`col-12 mb-md-4 ${uppercase ? 'text-uppercase' : ''}`}>
        <h2 id={id} className={gradient ? styles.Gradient : styles.Title}>
          {children}
        </h2>
      </div>
    </div>
  </div>
);

Title.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  gradient: PropTypes.bool,
  uppercase: PropTypes.bool,
};

Title.defaultProps = {
  gradient: false,
  uppercase: false,
};

export default Title;
