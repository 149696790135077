import { Link } from 'react-router-dom';

import {
  Form,
  TextInput,
  SubmitButton,
} from 'custom-form-client/react';

import Section from '../Section';
import BookMeeting from '../BookMeeting';
import './Contact.scss';

import {
  CAPTCHA_URL_BASE,
  GRABOXY_API_KEY,
  TRAP_SERVER_URL,
} from '../../constants';

const Contact = () => {
  return (
    <Section
      id="Contact"
      title="Try the Free Graboxy Sentinel Demo Now"
      className="Contact"
      gradient
    >
      <div className="container my-0 py-0">
        <div className="row my-0 py-0">
          <div className="col-12 my-0 py-0">
            <Form
              id="form"
              className="form p-0"
              type="contact"
              action={process.env.REACT_APP_CUSTOM_FORM_URL}
              responseMessage="Thank you for your message."
              captchaType="graboxyCaptcha"
              captchaSiteKey={process.env.REACT_APP_GRABOXY_CAPTCHA_SITE_KEY}
              captchaOptions={{
                urlBase: CAPTCHA_URL_BASE,
                apiKey: GRABOXY_API_KEY,
                trapUrl: TRAP_SERVER_URL,
              }}
            >
              <p className="description">
                Fill in the form to receive a link to our free Demo or to send
                us a message.
              </p>
              <div className="form__inner">
                <TextInput
                  id="name"
                  label="Name"
                  className="form__inner--row"
                  required
                />

                <TextInput
                  id="email"
                  type="email"
                  label="E-mail"
                  className="form__inner--row"
                  required
                />

                <TextInput
                  id="company"
                  label="Company"
                  className="form__inner--row"
                />

                <TextInput
                  id="message"
                  label="Message"
                  className="form__inner--row"
                  multiline
                />

                <div className="check text-start">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="subscribe"
                      name="subscribe"
                      className="custom-control-input"
                    />
                    <label htmlFor="subscribe" className="custom-control-label">
                      Send me news about product updates
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="accept"
                      name="accept"
                      className="custom-control-input"
                      required
                    />
                    <label htmlFor="accept" className="custom-control-label">
                      I agree to the
                      {' '}
                      <Link to="/privacy" className="mr-4" target="_blank">
                        Privacy Policy
                      </Link>
                      <small>(Required)</small>
                    </label>
                  </div>
                </div>

                <div className="submit-container">
                  <SubmitButton className="btn">Send</SubmitButton>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <BookMeeting />
    </Section>
  );
};

export default Contact;
