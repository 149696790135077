import React from 'react';
import 'reactjs-popup/dist/index.css';

import Section from '../Section';
import './Email.scss';

const Email = () => {
  return (
    <Section
      id="Email"
      title="Have an exclusive sneak peek into our technology in action"
      className="email"
      style={{ minHeight: 300 }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 form">
            <div className="form__img">
              <iframe
                src="https://player.vimeo.com/video/694349144?h=860e26417e"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Email;
