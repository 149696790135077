import stepExploit from '../../images/step--exploit.png';
import stepWebsite from '../../images/step--website.png';
import stepPlay from '../../images/step--play.png';

const Steps = () => (
  <section className="steps" style={{ minHeight: 1700 }}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="steps__inner">
            <div className="steps__inner--step">
              <div className="step-img">
                <img src={stepWebsite} alt="Website" />
              </div>
              <div className="step-content">
                <h3>Movements reveal identity</h3>
                <p>
                  Human motion is unique to individuals. Fine motor movements
                  captured while moving the cursor provide a level of
                  uniqueness that cannot be exactly repeated.
                </p>
              </div>
            </div>
            <div className="steps__inner--step">
              <div className="step-content">
                <h3>Biometric authentication</h3>
                <p>
                  Our award-winning machine learning technology recognises in
                  real time if an unauthorised individual is impersonating an
                  authentic user.
                </p>
              </div>
              <div className="step-img">
                <img src={stepPlay} alt="Play" />
              </div>
            </div>
            <div className="steps__inner--step">
              <div className="step-img">
                <img src={stepExploit} alt="Exploit" />
              </div>
              <div className="step-content">
                <h3>Win-win for CISOs and users</h3>
                <p>
                  Stop fraudsters or prying roommates access your systems.
                  Spare your users from grueling password policies and having
                  to use 2FA every time they log in.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Steps;
