import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import PostCard from './PostCard'

const SETTINGS = {
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  speed: 200,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Posts = ({ posts }) => {
  const renderedPosts = posts.map((post) => (
    <PostCard key={post.id} post={post} />
  ));

  return <div className="row">
    <Slider {...SETTINGS} className="h-100">
      {renderedPosts}
    </Slider>
  </div>;
}

Posts.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

Posts.defaultProps = {
  posts: [],
};

export default Posts;
