import React from 'react';

import PageLayout from '../../layouts/PageLayout';
import Contact from '../../components/Contact';

const DemoForm = () => (
  <PageLayout>
    <Contact />
  </PageLayout>
);

export default DemoForm;
