import { Navbar, Nav } from 'react-bootstrap';
import ContactButton from '../ContactButton';

import { GRABOXY_URL } from '../../constants';
import logo from '../../images/inverse-logo-graboxy-sentinel.svg';
import logo2fa from '../../images/inverse-logo-graboxy-2fa.svg';
import logoanticheat from '../../images/inverse-logo-graboxy-anticheat.svg';
import logocaptcha from '../../images/inverse-logo-graboxy-captcha.svg';
import logocursor from '../../images/inverse-logo-cursor-insight.svg';

const Header = () => (
  <header>
    <Navbar collapseOnSelect expand="lg">
      <div id="menuToggle" className="only-desktop">
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          <h3>Graboxy Products</h3>
          <li>
            <a
              href="https://sentinel.graboxy.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logo} alt="Graboxy Sentinel logo" />
            </a>
          </li>
          <li>
            <a
              href="https://2fa.graboxy.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logo2fa} alt="Graboxy 2FA logo " />
            </a>
          </li>
          <li>
            <a
              href="https://anti-cheat.graboxy.com/#/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logoanticheat} alt="Graboxy Anti-Cheat logo" />
            </a>
          </li>
          <li>
            <a
              href="https://captcha.graboxy.com/#/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logocaptcha} alt="Graboxy CAPTCHA logo" />
            </a>
          </li>
          <h3 className="mt-4">Company Website</h3>
          <li>
            <a
              href="https://cursorinsight.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logocursor} alt="Cursor Insight logo" />
            </a>
          </li>
        </ul>
      </div>
      <div className="container">
        <Navbar.Brand className="logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              target="_blank"
              href={`${GRABOXY_URL}/blog/tag/sentinel`}
            >
              Blog
            </Nav.Link>
          </Nav>
          <Nav>
            <ContactButton />
          </Nav>
          <ul className="product-nav only-mobile">
            <h3>Graboxy Products</h3>
            <li>
              <a
                href="https://sentinel.graboxy.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logo} alt="Graboxy Sentinel logo" />
              </a>
            </li>
            <li>
              <a
                href="https://2fa.graboxy.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logo2fa} alt="Graboxy 2FA logo" />
              </a>
            </li>
            <li>
              <a
                href="https://anti-cheat.graboxy.com/#/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoanticheat} alt="Graboxy Anti-Cheat logo" />
              </a>
            </li>
            <li>
              <a
                href="https://captcha.graboxy.com/#/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logocaptcha} alt="Graboxy CAPTCHA logo" />
              </a>
            </li>
            <h3 className="mt-1">Company Website</h3>
            <li>
              <a
                href="https://cursorinsight.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logocursor} alt="Cursor Insight logo" />
              </a>
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  </header>
);

export default Header;
