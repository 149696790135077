import React from 'react';
import PropTypes from 'prop-types';

import styles from './Feature.module.scss';

const Feature = ({ img, title, children }) => (
  <div className="col-lg-6 mb-4">
    <div className={styles.Feature}>
      <img src={img} alt={title} />
      <div>
        <h6>{title}</h6>
        <p>{children}</p>
      </div>
    </div>
  </div>
);

Feature.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default Feature;
