import React from 'react';
import Slider from 'react-slick';

import moritzGrumbach from '../../images/MoritzGrumbach.jpg';
import adamFeldmann from '../../images/AdamFeldmann.jpg';
import faridSingh from '../../images/FaridSingh.jpg';
import Quote from './Quote';

const SETTINGS = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Quotes = () => (
  <section className="quote">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Slider {...SETTINGS} className="slider">
            <Quote
              img={moritzGrumbach}
              name="Moritz Grumbach"
              title="Founder & Managing Director, Vertum.Group"
            >
              I had the pleasure of getting to know Cursor Insight and their
              product Graboxy in the course of the Cybernorth Accelerator. Not
              only has Graboxy been one of the very highlights of the program
              since its start, but also one of the few genius – and yet
              performing! – ideas that one wants to accompany, foster and boost
              just for its proper excellence.
            </Quote>

            <Quote
              img={adamFeldmann}
              name="Adam Feldmann"
              title="Head of Big Data Research Group, University of Pecs"
            >
              The professional and approachable team behind Graboxy truly
              understands the scientific demands of cutting edge technology
              research. They designed and carried out high quality research
              projects and are clearly capable of commercializing the
              outcomes.
            </Quote>

            <Quote
              img={faridSingh}
              name="Farid Singh"
              title="CEO, Take 3 Innovate"
            >
              Graboxy is changing how we think of digital sign-on and
              safety. Think simple, seamless and single device login,
              verification and anti fraud, in one service.
            </Quote>
          </Slider>
        </div>
      </div>
    </div>
  </section>
);

export default Quotes;
