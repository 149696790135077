import enterpriseIcon from '../../images/enterprise-icon.svg';
import homeofficeIcon from '../../images/homeoffice-icon.svg';
import paymentIcon from '../../images/credit-card-icon.svg';

import { Link } from 'react-scroll';
import Section from '../Section';
import UseCase from './UseCase';
import styles from './UseCases.module.scss';

const USE_CASES = [
  {
    img: enterpriseIcon,
    title: 'Enterprise accounts',
    children: 'Unauthorized access to company systems may lead to data breaches, CCPA/GDPR fines and damaged reputation.',
  },
  {
    img: homeofficeIcon,
    title: 'Home office',
    children: 'Working from home increases system vulnerabilities. That’s why 2 out of 3 successful enterprise cyberattacks target remote workers.',
  },
  {
    img: paymentIcon,
    title: 'Payment providers',
    children: 'Online banking and payment services are the top targets for cybercriminals, along with healthcare companies.',
  },
];

const UseCases = () => {
  const renderedUseCases = USE_CASES.map((u) => (
    <UseCase key={u.title} img={u.img} title={u.title}>
      {u.children}
    </UseCase>
  ));

  return (
    <Section
      id="UseCases"
      title="Use cases"
      className={styles.UseCases}
    >
      <div className="container">
        <div className="row">
          {renderedUseCases}
        </div>
        <div className="mt-4">
          <Link to="Contact" className="btn" smooth={true}>
            Request Demo
          </Link>
        </div>
      </div>
    </Section>
  );
}

export default UseCases;
