import React from 'react';
import PropTypes from 'prop-types';

import logo from '../images/inverse-logo-graboxy-sentinel.svg';

const Logo = () => (
  <img src={logo} alt="Logo" />
);

export const LogoContainer = ({ className }) => (
  <div className="container">
    <div className="row text-center">
      <div className={`col-12 ${className}`}>
        <Logo />
      </div>
    </div>
  </div>
);

LogoContainer.propTypes = {
  className: PropTypes.string,
};

LogoContainer.defaultProps = {
  className: undefined,
};

export default Logo;
