import React from 'react';
import PropTypes from 'prop-types';

import quote from '../../images/quote.png';

const Quote = ({ img, name, title, children }) => (
  <div className="slider__inner">
    <div className="slider__inner--image">
      <img src={img} alt={name} />
    </div>
    <div className="slider__inner--text">
      <div className="title-style">
        <h5>{name}</h5>
        <p>{title}</p>
      </div>
    </div>
    <img src={quote} className="quote" alt="Quote One" />
    <div className="text-style">
      <p>{children}</p>
    </div>
  </div>
);

Quote.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default Quote;
