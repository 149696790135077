import React from 'react';
import PropTypes from 'prop-types';

import { LogoContainer } from '../components/Logo';
import BaseLayout from './BaseLayout';

const DocumentLayout = ({ children }) => (
  <BaseLayout>
    <LogoContainer className="mt-5" />
    <main>{children}</main>
  </BaseLayout>
);

DocumentLayout.propTypes = {
  children: PropTypes.node,
};

DocumentLayout.defaultProps = {
  children: undefined,
};

export default DocumentLayout;
