import Slider from 'react-slick';
import Section from '../Section';
import styles from './Awards.module.scss';
import './AwardSlider.scss';

import award1 from '../../images/cyber_award.png';
import award2 from '../../images/finalist.png';
import award3 from '../../images/balabit_award.png';
import award4 from '../../images/50k_award.png';
import award5 from '../../images/cee_award.png';

const SETTINGS = {
  dots: true,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: true,
  speed: 200,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Awards = () => (
  <Section id="Awards" className={styles.Awards}>
    <div className="container">
      <div className="row">
        <Slider {...SETTINGS} className="AwardSlider">
          <div className="col-6 col-md-3">
            <div className={styles.AwardInner}>
              <img className={styles.FirstAward} src={award1} alt="" />
            </div>
            <p>Winner of the AI Cyber Product of the Year 2023</p>
          </div>
          <div className="col-6 col-md-3">
            <div className={styles.AwardInner}>
              <img src={award2} alt="" />
            </div>
            <p>Remote Monitoring Security Solution of the Year</p>
          </div>
          <div className="col-6 col-md-3">
            <div className={styles.AwardInner}>
              <img src={award3} alt="" />
            </div>
            <p>Winner of the Mouse Dynamics Challange competition of Balabit</p>
          </div>
          <div className="col-6 col-md-3">
            <div className={styles.AwardInner}>
              <img src={award4} alt="" />
            </div>
            <p>The 50 UK startups</p>
          </div>
          <div className="col-6 col-md-3">
            <div className={styles.AwardInner}>
              <img src={award5} alt="" />
            </div>
            <p>Top 10 CEE Scale-up by Vestbee</p>
          </div>
        </Slider>
      </div>
    </div>
  </Section>
);

export default Awards;
