import React from 'react';
import { Link } from 'react-scroll';

import styles from './Content.module.scss';

import badge from './cyber_award.png';

const Content = () => (
  <div className={styles.Content}>
    <img
      src={badge}
      alt="Cyber Award"
      width={280}
      className="mt-4 mb-3 py-2"
    />
    <h1>From cursor movement to user identity</h1>
    <p className="fw-bold">
      Unleash the world's most accurate biometric movement
      analysis technology.
    </p>

    <p>
      Protect your organization and service from identity fraud and
      account takeover with continuous biometric authentication.
    </p>

    <Link to="Contact" className="btn" smooth={true}>
      Request Demo
    </Link>
  </div>
);

export default Content;
