import React, { useEffect, useLayoutEffect } from 'react';

import PageLayout from '../layouts/PageLayout';
import Seo from '../components/seo/Seo';
import BackToHome from '../components/shared/BackToHome';

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

const PrototypeVideoPage = () => {
  useLayoutEffect(() => {
    let chatbot = undefined;

    window.LeadBooster.on('initialized', () => {
      chatbot = document.getElementById('LeadboosterContainer');
      chatbot.setAttribute('id', 'LeadboosterContainerHidden');
    });

    return () => {
      if (chatbot) {
        chatbot.setAttribute('id', 'LeadboosterContainer');
      }
    };
  }, []);

  return (
    <PageLayout>
      <Seo
        title="Graboxy Sentinel video"
        description="Graboxy Sentinel video"
      />

      <section className="prototypevideo">
        <div className="container">
          <BackToHome />
          <ScrollToTopOnMount />
          <h1>Graboxy Sentinel in action</h1>
          <p>
            In this one-minute video you can see Graboxy Sentinel prototype in
            action.
          </p>
          <p>
            The system creates a biometric fingerprint from the user's cursor
            movement and later detects the unauthorized intruder.
          </p>
          <div className="fluidMedia">
            <iframe
              src="https://player.vimeo.com/video/694349144?h=860e26417e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Graboxy Sentine PoC Solitaire"
            >
            </iframe>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default PrototypeVideoPage;
