import Section from '../Section';
import Mousey from '../Mousey';
import VideoContainer from './VideoContainer';
import Content from './Content';
import styles from './Hero.module.scss';

const Hero = () => (
  <Section id="Hero" className={styles.Hero}>
    <div className="container">
      <div className="row">
        <VideoContainer />
        <Content />
      </div>

      <div className="row">
        <div className="col-12">
          <Mousey to="Features" />
        </div>
      </div>
    </div>
  </Section>
);

export default Hero;
