import React from 'react';
import PropTypes from 'prop-types';

import Header from '../components/header/Header';
import BaseLayout from './BaseLayout';

const PageLayout = ({ children }) => (
  <BaseLayout>
    <Header />
    <main>{children}</main>
  </BaseLayout>
);

PageLayout.propTypes = {
  children: PropTypes.node,
};

PageLayout.defaultProps = {
  children: undefined,
};

export default PageLayout;
