import iconUser from '../../images/icon--user.svg';
import iconPrivacy from '../../images/icon--privacy.svg';
import iconEasy from '../../images/icon--easy.svg';
import iconStand from '../../images/icon--stand.svg';

import Section from '../Section';
import Feature from './Feature';

const FEATURES = [
  {
    img: iconUser,
    title: 'User authentication',
    children: 'Our AI continuously monitors cursor movement characteristics and reports suspicious behavior.',
  },
  {
    img: iconStand,
    title: 'Compliant with NIS2',
    children: 'Use our continuous authentication tool to make your company NIS2 compliant by 2024.',
  },
  {
    img: iconPrivacy,
    title: 'Fraud prevention',
    children: 'Use the risk score to automatically trigger MFA or lock out fraudulent users.',
  },
  {
    img: iconEasy,
    title: 'Seamless integration and UX',
    children: 'Invisible operation for users and easy-to-use dashboard for admins.',
  },
];

const Features = () => {
  const renderedFeatures = FEATURES.map((f) => (
    <Feature key={f.title} img={f.img} title={f.title}>
      {f.children}
    </Feature>
  ));

  return (
    <Section
      id="Features"
      title="Boost your cybersecurity"
      className="Features"
    >
      <div className="container">
        <div className="row">
          {renderedFeatures}
        </div>
      </div>
    </Section>
  );
}

export default Features;
