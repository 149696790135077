import React from 'react';
import PropTypes from 'prop-types'

import Title from './Title';

const Section = ({
  id, title, className, children, gradient, uppercase,
}) => (
  <section
    id={id}
    aria-labelledby={`${id}Section`}
    className={`py-5 ${className}`}
  >
    {title && (
      <Title
        id={`${id}Section`}
        gradient={gradient}
        uppercase={uppercase}
      >
        {title}
      </Title>
    )}
    {children}
  </section>
);

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  gradient: PropTypes.bool,
  uppercase: PropTypes.bool,
};

Section.defaultProps = {
  title: undefined,
  className: undefined,
  children: undefined,
  gradient: false,
  uppercase: false,
};

export default Section;
