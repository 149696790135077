import React from 'react';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';

import styles from './Mousey.module.scss';

const Mousey = ({ to }) => (
  <Link to={to} smooth={true} offset={-100} className={styles.Mousey}>
    <div />
  </Link>
);

Mousey.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Mousey;
