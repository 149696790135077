import React from 'react';
import Slider from 'react-slick';

import dashboard from '../../images/Dashboard.png';
import users from '../../images/Users.png';
import rules from '../../images/Rules.png';
import reports from '../../images/Reports.png';

const SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    }
  ]
};

const Dashboard = () => (
  <section className="dashboard">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="title">Flexible dashboard</h2>
          <p>
            Real time monitoring of incidents and system performance.
            Get reports and react to threats at user level.
            Set rules for different user groups to specify the security
            response. Check the details of account takeover alerts.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Slider {...SETTINGS} className="slider">
            <div className="dashboard__item">
              <img src={dashboard} alt="dashboard" />
            </div>
            <div className="dashboard__item">
              <img src={users} alt="users" />
            </div>
            <div className="dashboard__item">
              <img src={rules} alt="rules" />
            </div>
            <div className="dashboard__item">
              <img src={reports} alt="reports" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  </section>
);

export default Dashboard;
